<template>
  <div>
    <ui-header :heading="$tc('models.user', 2)">
      <ui-link class="add_new_button" type="text" :route="{ name: 'buildings-users-create' }">{{
        `${$tc('actions.add_new', 1)} ${$tc('models.user')}`
      }}</ui-link>
    </ui-header>

    <building-user-list
      :users="users"
      v-on:removeUser="removeUser"
      v-loading="loading"
    >
    </building-user-list>
  </div>
</template>

<script>
import UiHeader from '@/components/ui/UiHeader';
import UiLink from '@/components/ui/UiLink';
import BuildingUserList from '../components/BuildingUserList';
import {getBuilding, getBuildingUsers, removeBuildingUser} from '../api';

export default {
  data() {
    return {
      users: [],
      loading: false
    };
  },

  components: {
    UiHeader,
    UiLink,
    BuildingUserList
  },

  methods: {
    async getUsers() {
      this.loading = true;
      try {
        const users = await getBuildingUsers(
          this.$router.history.current.params.id
        );
        if (users.length > 0) {
          this.users = users.sort((a, b) => (a.lastName > b.lastName ? 1 : -1));
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        const msg = {
          message: this.$t('errors.load', { msg: this.$tc('user', 2) }),
          type: 'error'
        };
        this.$message(msg);
      }
    },

    async removeUser(userId) {
      this.loading = true;
      try {
        const success = await removeBuildingUser(
          this.$router.history.current.params.id,
          userId
        );
        if (success) this.users = this.users.filter(u => u.id !== userId);
      } catch (error) {
        this.$message({ message: this.$t('errors.general'), type: 'error' });
      }
      this.loading = false;
    }
  },

  created() {
    this.getUsers();
  },

  async mounted() {
    let buildingId = this.$route.params.id;
    let building = await getBuilding(buildingId);
    let buildingName = building.name;
    this.$store.commit('changeBreadcrumbs', [
      {name: "models.buildings", link: "/app/buildings/"},
      {name: buildingName, link: "/app/buildings/" + buildingId},
      {name: "models.users", link: ""}
    ]);
  },
};
</script>

<style scoped>
.add_new_button {
  background-color: white;
  border: solid 1px black;
  color: black;
}
</style>